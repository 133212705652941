<template>
  <v-navigation-drawer app v-model="drawer" dark width="230">
    <v-divider class="mb-1" />
    <v-list dense nav elevation="5" color="drawerColorSecondary">
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <!--Imagen de perfil-->
          <v-icon>$custom</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <!--Datos de usuario-->
          <v-list-item-title class="display-1" v-text="cuenta.nombre" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item :key="`item-${i}`" :item="item" />
      </template>
      <div />
    </v-list>

    <template v-slot:append>
      <div>
        <base-item
          :item="{
            title: $t('salir'),
            icon: 'mdi-arrow-up-bold-box-outline',
          }"
          color="dark"
          @click="logout"
        />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "DashboardCoreDrawer",
  data: () => ({
    items: [
      {
        //icon: 'mdi-account',
        title: "crearPrestamo",
        to: { name: "crearPrestamo" },
      },
      {
        title: "perfilUsuarioAlumno",
        to: { name: "perfilUsuarioAlumno" },
      },
      {
        title: "consultarMisPrestamos",
        to: { name: "consultarMisPrestamos" },
      },
      {
        title: "paquetesPublicos",
        to: { name: "paquetesPublicos" },
      },
    ],
  }),
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["barColor", "barImage", "cuenta"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    salir() {
      //console.log("Salir");
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout: function () {
      this.$store.dispatch("logout");
      this.$router.push("/SPML/login");
      const w = window.open('https://accounts.google.com/Logout');        
    },
  },
};
</script>

<style scoped>
</style>
